import cls from "./Button.module.scss";
import {Loader} from "../Loader/Loader";

export const Button = (props) => {
  const { children, isLoading, ...otherProps } = props;

  return (
    <button className={cls.button} {...otherProps}>
      {isLoading ? (
        <Loader />
      ) : (
        children
      )}
    </button>
  );
};
