import cls from "./Loader.module.scss";

export const Loader = (props) => {
  const { color } = props;

  const style = `${cls.ldsRing} ${color ? cls.color : ""}`;

  return (
    <div className={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
