import axios from 'axios';

const domain =
  process.env.REACT_APP_BASE_API_REMOVE_SUBDOMAIN
    ? process.env.REACT_APP_BASE_URL.replace(process.env.REACT_APP_BASE_API_REMOVE_SUBDOMAIN, '')
    : process.env.REACT_APP_BASE_URL;

export const apiAxiosInstance = axios.create({
  baseURL: 'https://api.' + domain || 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});
